import outerHeight from '@reef/js/fn/style/outerHeight';
import outerWidth from '@reef/js/fn/style/outerWidth';
import each from '@reef/js/helpers/collection/each';
import debounce from '@reef/js/helpers/function/debounce';
import selectAll from '@reef/js/fn/select/selectAll';
import setUndefined from '@reef/js/helpers/object/setUndefined';
import sleep from '@reef/js/methods/utils/sleep';
import EventsManager from '@reef/js/utilities/EventsManager';
import ScrollVibe from '@reef/js/utilities/ScrollVibe';

/*
 * Zeus functionalities
 *
 * @author Funky Fizz
 * @copyright 2021-present Petra Baggia
 *
 * @param {Node} el
 * @param {Object} options
 */
const Zeus = function (el, options) {
    const that = this;

    // Options - Selector
    setUndefined(options, {
        selector: Zeus.SELECTOR
    });

    // Selectors
    const selectors = {

        // App
        carousel: '.js-carousel',
        popinPreload: '.js-popin__preload'
    };

    // Element, Options, Selectors & Events
    that.el = el;
    that.options = options;
    that.selectors = selectors;
    that.events = new EventsManager();

    // Init
    that.init();
};

/**
 * Init
 */
Zeus.prototype.init = function () {
    const that = this;

    // Elements
    const elCarousels = selectAll(that.selectors.carousel, that.el);
    const elPopinPreloads = selectAll(that.selectors.popinPreload, that.el);

    // Ensure that all ScrollTriggers and Carousels are correctly refreshed once the
    // DOM is fully loaded
    sleep(50).then(() => {
        ScrollVibe.refresh();

        // Refresh carousels
        each(elCarousels, elCarousel => {
            that.events.trigger(elCarousel, 'handleRefresh');
        });

        // ScrollVibe is set not to listen to window resize events because it
        // creates problems on mobile devices. Therefore a resize listener takes
        // care of that task
        let width = outerWidth(that.el);
        let height = outerHeight(that.el);
        that.events.on(that.el, 'resize', debounce(() => {
            const newWidth = outerWidth(that.el);
            const newHeight = outerHeight(that.el);
            if (outerWidth(that.el) !== width || outerHeight(that.el) !== height) {
                width = newWidth;
                height = newHeight;
                if (!ScrollVibe.isScrolling()) {
                    ScrollVibe.refresh();
                }
            }
        }, 250, false));
    });

    // Refresh carousels with preloaded Popins as they can be loaded before being
    // displayed
    each(elPopinPreloads, elPopin => {
        that.events.on(elPopin, 'onShow', () => {
            each(selectAll(that.selectors.carousel, elPopin), elCarousel => {
                that.events.trigger(elCarousel, 'handleRefresh');
            });
        });
    });
};

/**
 * Destroy
 */
Zeus.prototype.destroy = function () {
    const that = this;

    that.events.destroy();
};

/**
 * Constants
 */
Zeus.SELECTOR = '.js-zeus';

export default Zeus;

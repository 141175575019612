import select from '@reef/js/fn/select/select';
import each from '@reef/js/helpers/collection/each';
import setUndefined from '@reef/js/helpers/object/setUndefined';
import className from '@reef/js/helpers/utils/className';
import EventsManager from '@reef/js/utilities/EventsManager';
import ScrollVibe from '@reef/js/utilities/ScrollVibe';

/*
 * Rentals functionalities
 *
 * @author Funky Fizz
 * @copyright 2021-present Petra Baggia
 *
 * @param {Node} el
 * @param {Object} options
 */
const Rentals = function (el, options) {
    const that = this;

    // Options - Selector
    setUndefined(options, {
        selector: Rentals.SELECTOR
    });

    // Selectors
    const selectors = {

        // Classes
        vBg1: '.v--bg-1',
        vBg2: '.v--bg-2',
        vBg3: '.v--bg-3',
        vBg4: '.v--bg-4',

        // Elements
        rental1: `${options.selector}__rental-1`,
        rental2: `${options.selector}__rental-2`,
        rental3: `${options.selector}__rental-3`,
        rental4: `${options.selector}__rental-4`

    };

    // Element, Options, Selectors & Events
    that.el = el;
    that.options = options;
    that.selectors = selectors;
    that.events = new EventsManager();
    that.vibes = [];

    // Init
    that.init();
};

/**
 * Init
 */
Rentals.prototype.init = function () {
    const that = this;

    // Elements
    const elRental1 = select(that.selectors.rental1, that.el);
    const elRental2 = select(that.selectors.rental2, that.el);
    const elRental3 = select(that.selectors.rental3, that.el);
    const elRental4 = select(that.selectors.rental4, that.el);

    // Classes
    const classBg1 = className(that.selectors.vBg1);
    const classBg2 = className(that.selectors.vBg2);
    const classBg3 = className(that.selectors.vBg3);
    const classBg4 = className(that.selectors.vBg4);

    // Create Vibes
    that.vibes.push(new ScrollVibe(that.el, {

        do: [

            // Rental #1
            {
                trigger: elRental1,
                target: that.el,
                start: 'top 50%',
                class: classBg1
            },

            // Rental #2
            {
                trigger: elRental2,
                target: that.el,
                start: 'top 50%',
                class: classBg2
            },

            // Rental #3
            {
                trigger: elRental3,
                target: that.el,
                start: 'top 50%',
                class: classBg3
            },

            // Rental #4
            {
                trigger: elRental4,
                target: that.el,
                start: 'top 50%',
                class: classBg4
            }
        ]
    }));
};

/**
 * Destroy
 */
Rentals.prototype.destroy = function () {
    const that = this;

    that.events.destroy();
    each(that.vibes, vibe => {
        vibe.destroy();
    });
};

/**
 * Constants
 */
Rentals.SELECTOR = '.js-rentals';

export default Rentals;

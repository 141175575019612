import addClass from '@reef/js/fn/attributes/addClass';
import hasClass from '@reef/js/fn/attributes/hasClass';
import hide from '@reef/js/fn/attributes/hide';
import show from '@reef/js/fn/attributes/show';
import select from '@reef/js/fn/select/select';
import selectAll from '@reef/js/fn/select/selectAll';
import scrollLock from '@reef/js/fn/browser/scrollLock';
import each from '@reef/js/helpers/collection/each';
import setUndefined from '@reef/js/helpers/object/setUndefined';
import className from '@reef/js/helpers/utils/className';
import imageLoaded from '@reef/js/methods/utils/imageLoaded';
import EventsManager from '@reef/js/utilities/EventsManager';
import Morph from '@reef/js/utilities/Morph';
import Tweener from '@reef/js/utilities/Tweener';

/*
 * Navigation functionalities
 *
 * @author Funky Fizz
 * @copyright 2021-present Petra Baggia
 *
 * @param {Node} el
 * @param {Object} options
 */
const Navigation = function (el, options) {
    const that = this;

    // Options - Selector
    setUndefined(options, {
        selector: Navigation.SELECTOR
    });

    // Selectors
    const selectors = {

        // Statuses
        vLoaded: '.v--loaded',

        // Lazy Images
        lazy: '.js-lazy',
        lazyPreload: '.js-lazy--preload',

        // Elements
        toggle: `${options.selector}__toggle`,
        close: `${options.selector}__close`,

        bg: `${options.selector}__bg`,
        bgAnimation: `${options.selector}__bg-animation`,

        media: `${options.selector}__media`,
        mediaImg: `${options.selector}__media-img`,

        menu: `${options.selector}__menu`,
        menuItem: `${options.selector}__menu-item`,
        menuItemReveal: `${options.selector}__menu-item-reveal`,

        submenu: `${options.selector}__submenu`,
        submenuItem: `${options.selector}__submenu-item`,

        logo: `${options.selector}__logo`
    };

    // Element, Options, Selectors & Events
    that.el = el;
    that.options = options;
    that.selectors = selectors;
    that.events = new EventsManager();

    // Init
    that.init();
};

/**
 * Init
 */
Navigation.prototype.init = function () {
    const that = this;

    // Elements
    const elToggles = selectAll(that.selectors.toggle);

    // Variables
    that.isOpen = false;
    that.isUpdating = false;

    // Listener - Toggles
    that.events.on(elToggles, 'click', e => {
        e.preventDefault();
        if (!that.isUpdating) {
            if (that.isOpen) {
                that.events.trigger(that.el, 'handleClose');
            } else {
                that.events.trigger(that.el, 'handleOpen');
            }
        }
    });

    // Listener - Open / Handle
    that.events.on(that.el, 'handleOpen', () => {
        that.open();
    });

    // Listener - Close / Handle
    that.events.on(that.el, 'handleClose', () => {
        that.close();
    });
};

/**
 * Open
 */
Navigation.prototype.open = function () {
    const that = this;

    // Elements
    const elBg = select(that.selectors.bg, that.el);
    const elBgAnimation = select(that.selectors.bgAnimation, elBg);

    const elMedia = select(that.selectors.media, that.el);
    const elMediaImg = select(that.selectors.mediaImg, elMedia);

    const elMenu = select(that.selectors.menu, that.el);
    const elMenuItems = selectAll(that.selectors.menuItem, elMenu);
    const elMenuItemReveals = selectAll(that.selectors.menuItemReveal, elMenu);

    const elSubmenuItems = selectAll(that.selectors.submenuItem, that.el);

    const elClose = select(that.selectors.close, that.el);
    const elLogo = select(that.selectors.logo, that.el);

    const elWave = select('#animation-wave-right');
    const elWaveFlat = select('#animation-wave-right-flat', elWave);
    const elWaveBump = select('#animation-wave-right-bump', elWave);

    // Classes
    const classLoaded = className(that.selectors.vLoaded);
    const classLazy = className(that.selectors.lazy);
    const classLazyPreload = className(that.selectors.lazyPreload);

    // Fct - Init
    const init = () => {
        return new Promise(resolve => {
            that.isOpen = true;
            that.isUpdating = true;
            scrollLock(true);
            Tweener.set(elMedia, { opacity: 0 });
            Tweener.set(elMenuItems, { opacity: 0 });
            Tweener.set(elSubmenuItems, { opacity: 0 });
            Tweener.set([elClose, elLogo], { y: '-100%' });
            show(that.el);
            resolve();
        });
    };

    // Fct - Bg -> Show
    const bgShow = () => {
        return new Promise(resolve => {

            // Tween
            const t = new Tweener({ speed: 500, ease: 'power2.inOut' })
                .add(elBgAnimation, {
                    scaleX: { from: 0, to: 1 }
                });

            // Morph
            const m = new Morph({ speed: 250, ease: 'sine.inOut' })
                .add(elBgAnimation, {
                    from: elWaveFlat,
                    to: elWaveBump
                })
                .add(elBgAnimation, {
                    to: elWaveFlat
                }, true);

            // Play
            Promise.all([t.play(), m.play()]).then(() => {
                resolve();
            });
        });
    };

    // Fct - Close -> Show
    const closeShow = () => {
        return new Promise(resolve => {
            new Tweener({ speed: 400, ease: 'power2.inOut' })
                .add(elClose, {
                    y: { to: 0 }
                })
                .play().then(() => {
                    resolve();
                });
        });
    };

    // Fct - Logo -> Show
    const logoShow = () => {
        return new Promise(resolve => {
            new Tweener({ speed: 400, ease: 'power2.inOut' })
                .add(elLogo, {
                    y: { to: 0 }
                })
                .play().then(() => {
                    resolve();
                });
        });
    };

    // Fct - Menu -> Show
    const menuShow = () => {
        return new Promise(resolve => {
            const t = new Tweener({ speed: 450, ease: 'power2.inOut' });
            each(elMenuItemReveals, (el, i) => {
                t.add(el, {
                    y: { from: '100%', to: 0, delay: i * 50 }
                });
            });
            t
                .onStart(() => {
                    Tweener.set(elMenuItems, { opacity: 1 });
                })
                .play().then(() => {
                    resolve();
                });
        });
    };

    // Fct - Submenu -> Show
    const submenuShow = () => {
        return new Promise(resolve => {
            const t = new Tweener({ speed: 250, ease: 'power3.out' });
            each(elSubmenuItems, (el, i) => {
                t.add(el, {
                    x: { from: 25, to: 0, delay: 250 + i * 25 },
                    opacity: { from: 0, to: 1, delay: 250 + i * 25, ease: 'power1.out' }
                });
            });
            t.play().then(() => {
                resolve();
            });
        });
    };

    // Fct - Media -> Load
    const mediaLoad = () => {
        return new Promise(resolve => {
            if (hasClass(elMedia, classLoaded)) {
                resolve();
            } else {
                imageLoaded(elMedia).then(() => {
                    addClass(elMedia, classLoaded);
                    resolve();
                });
                addClass(select('img', elMedia), [classLazy, classLazyPreload]);
            }
        });
    };

    // Fct - Media -> Show
    const mediaShow = () => {
        return new Promise(resolve => {
            mediaLoad().then(() => {
                if (that.isOpen) {
                    new Tweener({ speed: 500, ease: 'power3.in' })
                        .add(elMediaImg, {
                            scaleX: { from: 1.1, to: 1 },
                            scaleY: { from: 1.1, to: 1, ease: 'power1.in' }
                        })
                        .add(elMedia, {
                            opacity: { to: 1 }
                        })
                        .play()
                        .then(() => {
                            resolve();
                        });
                }
            });
        });
    };

    // Fct - Done
    const done = () => {
        return new Promise(resolve => {
            that.isUpdating = false;
            that.events.trigger(that.el, 'onOpen');
            resolve();
        });
    };

    // Init & Proceed
    init().then(() => {
        bgShow().then(() => {
            Promise.all([menuShow(), submenuShow(), logoShow(), closeShow(), mediaShow()]).then(() => {
                done();
            });
        });
    });
};

/**
 * Close
 */
Navigation.prototype.close = function () {
    const that = this;

    // Elements
    const elBg = select(that.selectors.bg, that.el);
    const elBgAnimation = select(that.selectors.bgAnimation, elBg);

    const elMedia = select(that.selectors.media, that.el);

    const elMenu = select(that.selectors.menu, that.el);
    const elMenuItemReveals = selectAll(that.selectors.menuItemReveal, elMenu);

    const elSubmenuItems = selectAll(that.selectors.submenuItem, that.el);

    const elClose = select(that.selectors.close, that.el);
    const elLogo = select(that.selectors.logo, that.el);

    const elWave = select('#animation-wave-left');
    const elWaveFlat = select('#animation-wave-left-flat', elWave);
    const elWaveBump = select('#animation-wave-left-hollow', elWave);

    // Fct - Init
    const init = () => {
        return new Promise(resolve => {
            that.isUpdating = true;
            resolve();
        });
    };

    // Fct - Bg -> Hide
    const bgHide = () => {
        return new Promise(resolve => {

            // Tween
            const t = new Tweener({ speed: 500, ease: 'power2.inOut' })
                .add(elBgAnimation, {
                    scaleX: { to: 0 },
                    x: { to: '100%' }
                });

            // Morph
            const m = new Morph({ speed: 250, ease: 'sine.inOut' })
                .add(elBgAnimation, {
                    from: elWaveFlat,
                    to: elWaveBump
                })
                .add(elBgAnimation, {
                    to: elWaveFlat
                }, true);

            // Play
            Promise.all([t.play(), m.play()]).then(() => {
                resolve();
            });
        });
    };

    // Fct - Menu - Hide
    const menuHide = () => {
        return new Promise(resolve => {
            const t = new Tweener({ speed: 450, ease: 'power2.inOut' });
            each(elMenuItemReveals, (el, i) => {
                t.add(el, {
                    y: { to: '-100%', delay: i * 50 }
                });
            });
            t.play().then(() => {
                resolve();
            });
        });
    };

    // Fct - Submenu -> Hide
    const submenuHide = () => {
        return new Promise(resolve => {
            const t = new Tweener({ speed: 250, ease: 'power3.in' });
            each(elSubmenuItems, (el, i) => {
                t.add(el, {
                    x: { to: -25 },
                    opacity: { to: 0, ease: 'power1.out' }
                });
            });
            t.play().then(() => {
                resolve();
            });
        });
    };

    // Fct - Media -> Hide
    const mediaHide = () => {
        return new Promise(resolve => {
            new Tweener({ speed: 500, ease: 'power3.in' })
                .add(elMedia, {
                    opacity: { to: 0 }
                })
                .play().then(() => {
                    resolve();
                });
        });
    };

    // Fct - Close -> Hide
    const closeHide = () => {
        return new Promise(resolve => {
            new Tweener({ speed: 400, ease: 'power2.inOut' })
                .add(elClose, {
                    y: { to: '-100%' }
                })
                .play().then(() => {
                    resolve();
                });
        });
    };

    // Fct - Logo -> Hide
    const logoHide = () => {
        return new Promise(resolve => {
            new Tweener({ speed: 400, ease: 'power2.inOut' })
                .add(elLogo, {
                    y: { to: '-100%' }
                })
                .play().then(() => {
                    resolve();
                });
        });
    };

    // Fct - Done
    const done = () => {
        return new Promise(resolve => {
            that.isOpen = false;
            that.isUpdating = false;
            scrollLock(false);
            hide(that.el);
            Tweener.clear(elBgAnimation, 'y');
            that.events.trigger(that.el, 'onClose');
            resolve();
        });
    };

    // Init & Proceed
    init().then(() => {
        Promise.all([menuHide(), submenuHide(), mediaHide(), logoHide(), closeHide()]).then(() => {
            bgHide().then(() => {
                done();
            });
        });
    });
};

/**
 * Destroy
 */
Navigation.prototype.destroy = function () {
    const that = this;

    that.events.destroy();
};

/**
 * Constants
 */
Navigation.SELECTOR = '.js-navigation';

export default Navigation;

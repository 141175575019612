import addClass from '@reef/js/fn/attributes/addClass';
import getAttr from '@reef/js/fn/attributes/getAttr';
import hasClass from '@reef/js/fn/attributes/hasClass';
import hide from '@reef/js/fn/attributes/hide';
import removeClass from '@reef/js/fn/attributes/removeClass';
import show from '@reef/js/fn/attributes/show';
import serialize from '@reef/js/fn/form/serialize';
import html from '@reef/js/fn/manipulation/html';
import select from '@reef/js/fn/select/select';
import parseBool from '@reef/js/helpers/lang/parseBool';
import setUndefined from '@reef/js/helpers/object/setUndefined';
import className from '@reef/js/helpers/utils/className';
import ajax from '@reef/js/methods/ajax/ajax';
import scroll from '@reef/js/methods/browser/scroll';
import attrOption from '@reef/js/utilities/.internal/options/attrOption';
import EventsManager from '@reef/js/utilities/EventsManager';

/*
 * Coral BookingCheckout main functionalities
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node} el
 * @param {Object} options
 */
const BookingCheckout = function (el, options) {
    const that = this;

    // Options - Selector
    setUndefined(options, {
        selector: BookingCheckout.SELECTOR,

        // Options
        type: BookingCheckout.TYPE,
        debug: BookingCheckout.DEBUG
    });

    // Set Options from Attr
    options = attrOption(options, el, ['popin', 'type', 'debug'], options.selector);

    // Boolean values
    options.debug = parseBool(options.debug);

    // Selectors
    const selectors = {

        // Statuses
        vLoading: '.v--loading',
        vHidden: '.v--hidden',
        vConfirm: '.v--confirm',

        // Elements
        form: `${options.selector}__form`,
        ajax: `${options.selector}__ajax`,
        loading: `${options.selector}__loading`,
        main: `${options.selector}__main`,
        progressCheckout: `${options.selector}__progress-checkout`,
        progressConfirm: `${options.selector}__progress-confirm`,
        estimate: `${options.selector}__estimate`

    };

    // Element, Options, Selectors & Events
    that.el = el;
    that.options = options;
    that.selectors = selectors;
    that.events = new EventsManager();

    // Init
    that.init();
};

/**
 * Init
 */
BookingCheckout.prototype.init = function () {
    const that = this;

    // Elements
    const elLoading = select(that.selectors.loading, that.el);
    const elAjax = select(that.selectors.ajax, that.el);
    const elMain = select(that.selectors.main, that.el);
    let elForm = select(that.selectors.form, elAjax);
    const elProgressCheckout = select(that.selectors.progressCheckout, that.el);
    const elProgressConfirm = select(that.selectors.progressConfirm, that.el);
    const elEstimate = select(that.selectors.estimate, that.el);

    // Classes
    const classLoading = className(that.selectors.vLoading);
    const classHidden = className(that.selectors.vHidden);
    const classConfirm = className(that.selectors.vConfirm);

    // Fct - Loading
    const loading = () => {
        addClass([elForm, elAjax, elLoading], classLoading);
        hide(elEstimate);
    };

    // Fct - Loaded
    const loaded = () => {
        removeClass([elForm, elAjax, elLoading], classLoading);
        show(elEstimate);
    };

    // Fct - Listen
    const listen = () => {

        if (elForm) {

            // Listener - Submit
            that.events.on(elForm, 'submit', e => {
                e.preventDefault();
                if (!hasClass(elForm, classLoading)) {
                    handleRequest(getAttr(e.target, 'action'), getAttr(e.target, 'method'), serialize(e.target));
                }
            });

            // Listener - Handle Submit
            that.events.on(elForm, 'handleSubmit', e => {
                e.preventDefault();
                if (!hasClass(elForm, classLoading)) {
                    handleRequest(getAttr(e.target, 'action'), getAttr(e.target, 'method'), serialize(e.target));
                }
            });
        }
    };

    // Fct - Handle Request
    const handleRequest = (url, method, data) => {
        loading();

        ajax(url, method, data)

            // Success - Render Confirmation
            .then(success => {
                loaded();
                html(elAjax, success);
                show(elEstimate);
                addClass(elProgressCheckout, classHidden);
                addClass(elEstimate, classConfirm);
                removeClass(elProgressConfirm, classHidden);
                listen();
            })

            // Error - Render Form
            .catch(error => {
                loaded();
                html(elAjax, error.response);
                elForm = select(that.selectors.form, elAjax);
                scroll(elMain, { speed: 500 });
                listen();
                if (that.options.debug) {
                    console.log(error);
                }
            });
    };

    // Start listening
    listen();
};

/**
 * Constants
 */
BookingCheckout.SELECTOR = '.js-bookingCheckout';
BookingCheckout.DEBUG = false;

export default BookingCheckout;

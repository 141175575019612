/*
 * App
 *
 * @author Funky Fizz
 * @copyright 2021-present Petra Baggia
 */

// App variables
import App from '@app/js/App';

// Plugins - App
import '@app/js/plugins/bookingCheckout';
import '@app/js/plugins/bookingDateArrival';
import '@app/js/plugins/header';
import '@app/js/plugins/hero';
import '@app/js/plugins/heroScroll';
import '@app/js/plugins/heroTxt';
import '@app/js/plugins/navigation';
import '@app/js/plugins/rental';
import '@app/js/plugins/rentals';
import '@app/js/plugins/puzzle';
import '@app/js/plugins/zeus';

// Plugins - Components

// Functions
import domReady from '@reef/js/fn/events/domReady';
import each from '@reef/js/helpers/collection/each';
import keys from '@reef/js/helpers/object/keys';

// Config
const config = {};

// Defaults
const defaults = {
    plugins: {}
};

// DOM Ready
domReady(() => {

    // Start App plugins
    each(keys(App.plugins), key => {
        App.plugins[key](defaults.plugins[key] || {});
    });
});

import FontFaceObserver from 'fontfaceobserver';
import hide from '@reef/js/fn/attributes/hide';
import select from '@reef/js/fn/select/select';
import each from '@reef/js/helpers/collection/each';
import parseBool from '@reef/js/helpers/lang/parseBool';
import setUndefined from '@reef/js/helpers/object/setUndefined';
import imageLoaded from '@reef/js/methods/utils/imageLoaded';
import attrOption from '@reef/js/utilities/.internal/options/attrOption';
import EventsManager from '@reef/js/utilities/EventsManager';
import ScrollVibe from '@reef/js/utilities/ScrollVibe';
import Tweener from '@reef/js/utilities/Tweener';

/*
 * Hero functionalities
 *
 * @author Funky Fizz
 * @copyright 2021-present Petra Baggia
 *
 * @param {Node} el
 * @param {Object} options
 */
const Hero = function (el, options) {
    const that = this;

    // Options - Selector
    setUndefined(options, {
        selector: Hero.SELECTOR,

        // Options
        autostart: Hero.AUTOSTART,
        scaleMedia: Hero.SCALE_MEDIA,
        smoothMedia: Hero.SMOOTH_MEDIA
    });

    // Set Options from Attr
    options = attrOption(options, el, ['autostart', 'scaleMedia', 'smoothMedia'], options.selector);

    // Boolean values
    options.autostart = parseBool(options.autostart);
    options.scaleMedia = parseBool(options.scaleMedia);
    options.smoothMedia = parseBool(options.smoothMedia);

    // Selectors
    const selectors = {

        // Elements
        loader: `${options.selector}__loader`,
        topography: `${options.selector}__topography`,
        media: `${options.selector}__media`,
        title: `${options.selector}__title`,
        upperTitle: `${options.selector}__upper-title`,
        reveal: `${options.selector}__reveal`,
        scroll: `${options.selector}__scroll`,
        btnScroll: `${options.selector}__btn-scroll`
    };

    // Element, Options, Selectors & Events
    that.el = el;
    that.options = options;
    that.selectors = selectors;
    that.events = new EventsManager();
    that.vibes = [];

    // Init
    that.init();
};

/**
 * Init
 */
Hero.prototype.init = function () {
    const that = this;

    // Listener - Show
    that.events.on(that.el, 'handleShow', () => {
        that.show();
    });

    // Autostart
    if (that.options.autostart) {
        that.events.trigger(that.el, 'handleShow');
    }
};

/**
 * Show
 */
Hero.prototype.show = function () {
    const that = this;

    // Elements
    const elLoader = select(that.selectors.loader, that.el);
    const elMedia = select(that.selectors.media, that.el);
    const elMediaImg = select('img', elMedia);
    const elTitle = select(that.selectors.title, that.el);
    const elUpperTitle = select(that.selectors.upperTitle, that.el);
    const elReveal = select(that.selectors.reveal, that.el);
    const elScroll = select(that.selectors.scroll, that.el);
    const elBtnScroll = select(that.selectors.btnScroll, that.el);

    // Fct - Init
    const init = () => {
        return new Promise(resolve => {
            resolve();
        });
    };

    // Fct - Loaded -> Fonts
    const loadedFonts = () => {
        return new Promise(resolve => {

            // Font Observers
            const observers = [];
            observers.push(new FontFaceObserver('gt-super-display').load(null, 5000));
            observers.push(new FontFaceObserver('matter').load(null, 5000));

            // Fonts Loaded
            Promise.all(observers)
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    resolve();
                });
        });
    };

    // Fct - Loaded -> Img
    const loadedImg = () => {
        return new Promise(resolve => {
            imageLoaded(elMedia).then(() => {
                resolve();
            });
        });
    };

    // Fct - Loaded
    const loaded = () => {
        return Promise.all([loadedFonts(), loadedImg()]);
    };

    // Fct - Loader -> Hide
    const loaderHide = () => {
        return new Promise(resolve => {
            new Tweener({ speed: 300, ease: 'power3.out' })
                .add(elLoader, {
                    scale: { to: 0 },
                    opacity: { to: 1, ease: 'power2.in' }
                })
                .play()
                .then(() => {
                    hide(elLoader);
                    resolve();
                });
        });
    };

    // Fct - Media -> Show
    const mediaShow = () => {
        return new Promise(resolve => {
            new Tweener({ speed: 600 })
                .add(elReveal, {
                    scaleY: { to: 0, ease: 'power1.inOut' },
                    y: { to: '-50%', ease: 'power1.inOut' }
                })
                .add(elMediaImg, {
                    scale: { from: 1.1, to: that.options.scaleMedia ? 1.05 : 1, ease: 'power1.in', delay: 100 }
                })
                .play()
                .then(() => {
                    resolve();
                });
        });
    };

    // Fct - Media -> Scale
    const mediaScale = () => {
        return new Promise(resolve => {
            if (that.options.scaleMedia) {
                new Tweener({ speed: 12000 })
                    .add(elMediaImg, {
                        scale: { to: 1, ease: 'power0.in' }
                    })
                    .play()
                    .then(() => {
                        resolve();
                    });
            } else {
                resolve();
            }
        });
    };

    // Fct - Title -> Show
    const titleShow = () => {
        return new Promise(resolve => {
            that.events.on(elTitle, 'onReveal', () => {
                new Tweener({ speed: 400 })
                    .add(elUpperTitle, {
                        opacity: { to: 1, ease: 'power1.in' },
                        x: { from: '4%', to: 0, ease: 'power1.out' }
                    })
                    .play()
                    .then(() => {
                        resolve();
                    });
            });
            that.events.trigger(elTitle, 'handleReveal');
        });
    };

    // Fct - Scroll -> Show
    const scrollShow = () => {
        return new Promise(resolve => {
            if (elScroll) {
                that.events.on(elScroll, 'onShow', () => {
                    resolve();
                });
                that.events.trigger(elScroll, 'handleShow');
            } else {
                resolve();
            }
        });
    };

    // Fct - Btn Scroll -> Show
    const btnScrollShow = () => {
        return new Promise(resolve => {
            if (elBtnScroll) {
                new Tweener({ speed: 400, ease: 'power1.inOut', delay: 400 })
                    .add(elBtnScroll, {
                        scale: { from: 0, to: 1 }
                    })
                    .onStart(() => {
                        Tweener.set(elBtnScroll, { opacity: 1 });
                    })
                    .play()
                    .then(() => {
                        Tweener.clear(elBtnScroll, 'scale')
                        resolve();
                    });
            } else {
                resolve();
            }
        });
    };

    // Fct - Vibe
    const vibe = () => {
        return new Promise(resolve => {
            if (that.options.smoothMedia) {
                 that.vibes.push(new ScrollVibe(elMedia, {
                    do: [
                        {
                            target: elMediaImg,
                            start: 'bottom 80%',
                            duration: '80%',
                            parallax: {
                                y: {
                                    to: '30%',
                                    ease: 'power0.out'
                                },
                                scale: {
                                    to: 1.1,
                                    ease: 'power0.out'
                                }
                            }
                        }
                    ]
                }));
            }
            resolve();
        });
    };

    // Fct - Done
    const done = () => {
        return new Promise(resolve => {
            resolve();
        });
    };

    // Init & Proceed
    init().then(() => {
        loaded().then(() => {
            loaderHide().then(() => {
                mediaShow().then(() => {
                    Promise.all([titleShow(), btnScrollShow()]).then(() => {
                        Promise.all([scrollShow(), mediaScale()]).then(() => {
                            vibe().then(() => {
                                done();
                            });
                        });
                    });
                });
            });
        });
    });
};

/**
 * Destroy
 */
Hero.prototype.destroy = function () {
    const that = this;

    that.events.destroy();
    each(that.vibes, vibe => {
        vibe.destroy();
    });
};

/**
 * Constants
 */
Hero.SELECTOR = '.js-hero';
Hero.AUTOSTART = true;
Hero.SCALE_MEDIA = false;
Hero.SMOOTH_MEDIA = false;

export default Hero;

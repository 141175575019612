import select from '../select/select';

/*
 * Return the width of the node, including borders and padding, excluding margins.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node|string} node
 *
 * @returns {number}
 */
export default function (node) {

    // Select matching node
    node = select(node);

    // Node provided
    return node ? node.offsetWidth : null;
}

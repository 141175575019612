import addClass from '@reef/js/fn/attributes/addClass';
import hide from '@reef/js/fn/attributes/hide';
import removeClass from '@reef/js/fn/attributes/removeClass';
import show from '@reef/js/fn/attributes/show';
import select from '@reef/js/fn/select/select';
import each from '@reef/js/helpers/collection/each';
import setUndefined from '@reef/js/helpers/object/setUndefined';
import className from '@reef/js/helpers/utils/className';
import EventsManager from '@reef/js/utilities/EventsManager';
import ScrollVibe from '@reef/js/utilities/ScrollVibe';

/*
 * Rental functionalities
 *
 * @author Funky Fizz
 * @copyright 2021-present Petra Baggia
 *
 * @param {Node} el
 * @param {Object} options
 */
const Rental = function (el, options) {
    const that = this;

    // Options - Selector
    setUndefined(options, {
        selector: Rental.SELECTOR
    });

    // Selectors
    const selectors = {

        // Classes
        vGallery: '.v--gallery',
        vSticky: '.v--sticky',
        vDisabled: '.v--disabled',

        // Elements
        details: `${options.selector}__details`,

        aboutMore: `${options.selector}__about-more`,
        aboutToggle: `${options.selector}__about-toggle`,

        gallery: `${options.selector}__gallery`,
        carouselNav: `${options.selector}__carousel-nav`,

        sticky: `${options.selector}__sticky`
    };

    // Element, Options, Selectors & Events
    that.el = el;
    that.options = options;
    that.selectors = selectors;
    that.events = new EventsManager();
    that.vibes = [];

    // Init
    that.init();
};

/**
 * Init
 */
Rental.prototype.init = function () {
    const that = this;

    // Init modules
    that.initLayout();
    that.initAbout();
    that.initGallery();
};

/**
 * Init - Layout
 */
Rental.prototype.initLayout = function () {
    const that = this;

    // Elements
    const elDetails = select(that.selectors.details, that.el);
    const elSticky = select(that.selectors.sticky, that.el);

    // Classes
    const classSticky = className(that.selectors.vSticky);
    const classDisabled = className(that.selectors.vDisabled);

    // Create the ScrollVibe in order to pin the CTA to the top when required
    that.vibes.push(new ScrollVibe(elDetails, {
        do: [
            {
                start: 'top top',
                pin: true,
                class: classSticky
            }
        ]
    }));

    // Create the ScrollVibe in order to pin the Sticky bar to the bottom
    that.vibes.push(new ScrollVibe(that.el, {
        do: [
            {
                start: 'top 50%',
                target: elSticky,
                class: classSticky
            },
            {
                start: 'bottom 85%',
                target: elSticky,
                class: classDisabled
            }
        ]
    }));
};

/**
 * Init - About
 */
Rental.prototype.initAbout = function () {
    const that = this;

    // Elements
    const elAboutMore = select(that.selectors.aboutMore, that.el);
    const elAboutToggle = select(that.selectors.aboutToggle, that.el);

    // Listener - Click
    that.events.on(elAboutToggle, 'click', e => {
        e.preventDefault();
        hide(elAboutToggle);
        show(elAboutMore);
    });
};

/**
 * Init - Gallery
 */
Rental.prototype.initGallery = function () {
    const that = this;

    // Elements
    const elGallery = select(that.selectors.gallery, that.el);
    const elCarouselNav = select(that.selectors.carouselNav, that.el);

    // Classes
    const classGallery = className(that.selectors.vGallery);

    // Listener - Show Start
    that.events.on(elGallery, 'onShowStart', e => {
        addClass(elCarouselNav, classGallery);
    });

    // Listener - Hide
    that.events.on(elGallery, 'onHide', e => {
        removeClass(elCarouselNav, classGallery);
    });
};

/**
 * Destroy
 */
Rental.prototype.destroy = function () {
    const that = this;

    that.events.destroy();
    each(that.vibes, vibe => {
        vibe.destroy();
    });
};

/**
 * Constants
 */
Rental.SELECTOR = '.js-rental';
Rental.AUTOSTART = true;

export default Rental;

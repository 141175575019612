import 'arrive';
import App from '@app/js/App';
import Zeus from '@app/js/utilities/Zeus';
import attr from '@reef/js/fn/attributes/attr';
import selectAll from '@reef/js/fn/select/selectAll';
import each from '@reef/js/helpers/collection/each';
import merge from '@reef/js/helpers/object/merge';
import parseJson from '@reef/js/helpers/string/parseJson';
import dataName from '@reef/js/helpers/utils/dataName';
import Plugins from '@reef/js/utilities/Plugins';

/*
 * Plugin loader for Zeus
 *
 * @author Funky Fizz
 * @copyright 2021-present Petra Baggia
 */

(app => {

    /**
     * Main
     *
     * @param {Object} options
     * @param {Node} context
     */
    app.plugins.zeus = function (options, context) {

        // Options
        options = merge(app.plugins.zeus.defaults, options);

        // Init
        const init = el => {
            if (Plugins.canRegister(el, options.selector)) {
                Plugins.register(el, options.selector, new Zeus(el, merge(options, parseJson(attr(el, dataName(options.selector))))));
            }
        };

        // Initial Init
        each(selectAll(options.selector, context), el => {
            init(el);
        });

        // Arriving Nodes
        document.arrive(options.selector, function () {
            init(this);
        });
    };

    /**
     * Defaults
     */
    app.plugins.zeus.defaults = {
        selector: Zeus.SELECTOR
    };

})(App);

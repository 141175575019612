import hide from '@reef/js/fn/attributes/hide';
import show from '@reef/js/fn/attributes/show';
import select from '@reef/js/fn/select/select';
import outerWidth from '@reef/js/fn/style/outerWidth';
import each from '@reef/js/helpers/collection/each';
import parseBool from '@reef/js/helpers/lang/parseBool';
import setUndefined from '@reef/js/helpers/object/setUndefined';
import attrOption from '@reef/js/utilities/.internal/options/attrOption';
import EventsManager from '@reef/js/utilities/EventsManager';
import Morph from '@reef/js/utilities/Morph';
import ScrollVibe from '@reef/js/utilities/ScrollVibe';
import Tweener from '@reef/js/utilities/Tweener';

/*
 * HeroScroll functionalities
 *
 * @author Funky Fizz
 * @copyright 2021-present Petra Baggia
 *
 * @param {Node} el
 * @param {Object} options
 */
const HeroScroll = function (el, options) {
    const that = this;

    // Options - Selector
    setUndefined(options, {

        // Selector
        selector: HeroScroll.SELECTOR,

        // Options
        autostart: HeroScroll.AUTOSTART
    });

    // Set Options from Attr
    options = attrOption(options, el, ['autostart'], options.selector);

    // Boolean values
    options.autostart = parseBool(options.autostart);

    // Selectors
    const selectors = {

        // Elements
        bg: `${options.selector}__bg`,
        bgAntialias: `${options.selector}__bg-antialias`,
        handle: `${options.selector}__handle`
    };

    // Element, Options, Selectors & Events
    that.el = el;
    that.options = options;
    that.selectors = selectors;
    that.events = new EventsManager();
    that.vibes = [];

    // Init
    that.init();
};

/**
 * Init
 */
HeroScroll.prototype.init = function () {
    const that = this;

    // Variable
    that.isMouseOver = false;
    that.isMouseAnimated = false;

    // Listener - Device Touchable
    that.events.on(document, 'onTouchable', () => {
        that.onTouchable();
    });

    // Listener - Device Not Touchable
    that.events.on(document, 'onNoTouchable', () => {
        that.onNoTouchable();
    });

    // Listener - Show
    that.events.on(that.el, 'handleShow', () => {
        that.show();
    });

    // Autostart
    if (that.options.autostart) {
        that.events.trigger(that.el, 'handleShow');
    }
};

/**
 * Show
 */
HeroScroll.prototype.show = function () {
    const that = this;

    // Elements
    const elBg = select(that.selectors.bg, that.el);
    const elBgLeft = select('#heroScroll-bg-left', elBg);
    const elBgRight = select('#heroScroll-bg-right', elBg);
    const elBgAntialias = select(that.selectors.bgAntialias, that.el);
    const elHandle = select(that.selectors.handle, that.el);
    const elHandleAnimation = select('#heroScroll-handle-animation', elHandle);
    const elHandleFlat = select('#heroScroll-handle-flat', elHandle);
    const elHandleArrow = select('#heroScroll-handle-arrow', elHandle);

    // Fct - Init
    const init = () => {
        return new Promise(resolve => {
            resolve();
        });
    };

    // Fct - Reveal
    const reveal = () => {
        return new Promise(resolve => {

            hide(elHandle);

            new Tweener({ speed: 400, ease: 'power1.in' })
                .addTweens(elBg, {
                    scaleY: { from: 0, to: 1 },
                    y: { from: '50%', to: 0 }
                }, false, true)
                .addTweens(elBgAntialias, {
                    scaleY: { from: 0, to: 1 },
                    y: { from: '50%', to: 0 }
                }, false, true)
                .onStart(() => {
                    show(that.el);
                })
                .play(() => {
                    Morph.set(elHandleAnimation, elHandleFlat);
                    new Tweener({ speed: 300, ease: 'power2.out' })
                        .addTweens(elHandle, {
                            scaleX: { from: 0, to: 1 },
                        }, false, true)
                        .onStart(() => {
                            show(elHandle);
                        })
                        .play(() => {
                            new Morph({ speed: 300, ease: 'sine.inOut' })
                                .add(elHandleAnimation, {
                                    to: elHandleArrow
                                })
                                .play(() => {
                                    that.isMouseAnimated = true;
                                    resolve();
                                });
                        });
                });
        });
    };

    // Fct - On Mouse Move
    const onMouseMove = e => {

        if (that.isMouseOver && that.isMouseAnimated) {

            // Middle of the screen
            const middleX = outerWidth(that.el) / 2;

            // Position where the items are supposed to go
            let toX = -middleX + e.offsetX;
            toX = Math.min(toX, middleX * 0.35);
            toX = Math.max(toX, -middleX * 0.35);

            // Percent of the screen moved
            const scaleLeft = 1 + toX / middleX;
            const scaleRight = 2 - scaleLeft;
            const xRight = 100 - 100 * scaleRight;

            // Tween
            new Tweener({ speed: 150, ease: 'power0.in' })
                .addTweens(elBgLeft, {
                    scaleX: { to: scaleLeft },
                }, false, true)
                .addTweens(elBgRight, {
                    scaleX: { to: scaleRight },
                    x: { to: `${xRight}%` }
                }, false, true)
                .addTweens(elHandle, {
                    x: { to: toX - outerWidth(elHandle) / 2 }
                }, false, true)
                .addTweens(elBgAntialias, {
                    x: { to: toX - outerWidth(elBgAntialias) / 2 }
                }, false, true)
                .play();
        }
    };

    // Fct - On Mouse Out
    const onMouseOut = () => {
        that.isMouseOver = false;

        new Tweener({ speed: 250, ease: 'power0.in' })
            .addTweens(elBgLeft, {
                scaleX: { to: 1 },
            }, false, true)
            .addTweens(elBgRight, {
                scaleX: { to: 1 },
                x: { to: 0 }
            }, false, true)
            .addTweens(elHandle, {
                x: { to: -outerWidth(elHandle) / 2 }
            }, false, true)
            .addTweens(elBgAntialias, {
                x: { to: -outerWidth(elBgAntialias) / 2 }
            }, false, true)
            .play();
    };

    // Fct - On Mouse Out
    const onMouseOver = () => {
        that.isMouseOver = true;
    };

    // Fct - Hide on Scroll
    const hideOnScroll = () => {
        return new Promise(resolve => {
            that.isMouseAnimated = false;
            new Morph({ speed: 300, ease: 'sine.inOut' })
                .add(elHandleAnimation, {
                    to: elHandleFlat
                })
                .play(() => {
                    new Tweener({ speed: 500, ease: 'power2.out' })
                        .addTweens(that.el, {
                            scaleY: { to: 0 },
                            y: { to: '50%' }
                        }, false, true)
                        .play(() => {
                            hide(that.el);
                        });
                });
        });
    };

    // Fct - Show on Scroll
    const showOnScroll = () => {
        return new Promise(resolve => {
            new Tweener({ speed: 500, ease: 'power2.out' })
                .addTweens(that.el, {
                    scaleY: { to: 1 },
                    y: { to: 0 }
                }, false, true)
                .onStart(() => {
                    show(that.el);
                })
                .play(() => {
                    new Morph({ speed: 300, ease: 'sine.inOut' })
                        .add(elHandleAnimation, {
                            to: elHandleArrow
                        })
                        .play(() => {
                            that.isMouseAnimated = true;
                            resolve();
                        });
                });
        });
    };

    // Fct - Done
    const done = () => {
        return new Promise(resolve => {
            that.events.trigger(that.el, 'onShow');

            // Listener - Mouse Move
            that.events.on(that.el, 'handleMousemove', e => {
                onMouseMove(e.detail);
            });

            // Listener - Mouse Over
            that.events.on(that.el, 'handleMouseover', e => {
                onMouseOver();
            });

            // Listener - Mouse Out
            that.events.on(that.el, 'handleMouseout', e => {
                onMouseOut();
            });

            // Listener - Hide on Scroll
            that.events.on(that.el, 'handleHideOnScroll', () => {
                hideOnScroll();
            });

            // Listener - Show on Scroll
            that.events.on(that.el, 'handleShowOnScroll', () => {
                showOnScroll();
            });

            // Create the ScrollVibe in order to track when the element has scrolled
            // over 80% of the viewport
            that.vibes.push(new ScrollVibe(that.el, {
                do: {
                    start: 'bottom 75%',
                    event: {
                        on: 'handleHideOnScroll',
                        off: 'handleShowOnScroll'
                    }
                }
            }));

            resolve();
        });
    };

    // Init & proceed
    init().then(() => {
        reveal().then(() => {
            done();
        });
    });
};

/**
 * Device - No Touchable
 */
HeroScroll.prototype.onNoTouchable = function () {
    const that = this;

    // Listener - Mouseover
    that.events.on(that.el, 'mouseenter', e => {
        that.events.trigger(that.el, 'handleMouseover', e);
    });

    // Listener - Mouseleave
    that.events.on(that.el, 'mouseleave', e => {
        that.events.trigger(that.el, 'handleMouseout', e);
    });

    // Listener - Mousemove
    that.events.on(document, 'mousemove', e => {
        that.events.trigger(that.el, 'handleMousemove', e);
    });
};

/**
 * Device - Touchable
 */
HeroScroll.prototype.onTouchable = function () {
    const that = this;

    // Remove listeners
    that.events.off(that.el, ['mouseover', 'mouseout']);
    that.events.off(document, 'mousemove');
};

/**
 * Destroy
 */
HeroScroll.prototype.destroy = function () {
    const that = this;

    that.events.destroy();
    each(that.vibes, vibe => {
        vibe.destroy();
    });
};

/**
 * Constants
 */
HeroScroll.SELECTOR = '.js-heroScroll';
HeroScroll.AUTOSTART = true;

export default HeroScroll;

import FontFaceObserver from 'fontfaceobserver';
import select from '@reef/js/fn/select/select';
import parseBool from '@reef/js/helpers/lang/parseBool';
import setUndefined from '@reef/js/helpers/object/setUndefined';
import sleep from '@reef/js/methods/utils/sleep';
import attrOption from '@reef/js/utilities/.internal/options/attrOption';
import EventsManager from '@reef/js/utilities/EventsManager';
import Tweener from '@reef/js/utilities/Tweener';

/*
 * HeroTxt functionalities
 *
 * @author Funky Fizz
 * @copyright 2021-present Petra Baggia
 *
 * @param {Node} el
 * @param {Object} options
 */
const HeroTxt = function (el, options) {
    const that = this;

    // Options - Selector
    setUndefined(options, {
        selector: HeroTxt.SELECTOR,

        // Options
        autostart: HeroTxt.AUTOSTART
    });

    // Set Options from Attr
    options = attrOption(options, el, ['autostart'], options.selector);

    // Boolean values
    options.autostart = parseBool(options.autostart);

    // Selectors
    const selectors = {

        // Elements
        upperTitle: `${options.selector}__upper-title`,
        title: `${options.selector}__title`,
        txt: `${options.selector}__txt`
    };

    // Element, Options, Selectors & Events
    that.el = el;
    that.options = options;
    that.selectors = selectors;
    that.events = new EventsManager();

    // Init
    that.init();
};

/**
 * Init
 */
HeroTxt.prototype.init = function () {
    const that = this;

    // Listener - Show
    that.events.on(that.el, 'handleShow', () => {
        that.show();
    });

    // Autostart
    if (that.options.autostart) {
        that.events.trigger(that.el, 'handleShow');
    }
};

/**
 * Show
 */
HeroTxt.prototype.show = function () {
    const that = this;

    // Elements
    const elTitle = select(that.selectors.title, that.el);
    const elUpperTitle = select(that.selectors.upperTitle, that.el);
    const elTxt = select(that.selectors.txt, that.el);

    // Fct - Init
    const init = () => {
        return new Promise(resolve => {
            resolve();
        });
    };

    // Fct - Loaded -> Fonts
    const loadedFonts = () => {
        return new Promise(resolve => {

            // Font Observers
            const observers = [];
            observers.push(new FontFaceObserver('gt-super-display').load(null, 5000));
            observers.push(new FontFaceObserver('matter').load(null, 5000));

            // Fonts Loaded
            Promise.all(observers)
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    resolve();
                });
        });
    };

    // Fct - Title -> Show
    const titleShow = () => {
        return new Promise(resolve => {
            that.events.on(elTitle, 'onReveal', () => {
                resolve();
            });
            sleep(50).then(() => {
                that.events.trigger(elTitle, 'handleReveal');
            });
        });
    };

    // Fct - Txt -> Show
    const txtShow = () => {
        return new Promise(resolve => {

            if (elTxt || elUpperTitle) {

                const t = new Tweener({ speed: 400, delay: 800 })

                if (elTxt) {
                    t.add(elTxt, {
                        opacity: { to: 1, ease: 'power1.in' },
                        x: { from: '4%', to: 0, ease: 'power1.out' }
                    })
                }

                if (elUpperTitle) {
                    t.add(elUpperTitle, {
                        opacity: { to: 1, ease: 'power1.in' },
                        x: { from: '4%', to: 0, ease: 'power1.out' }
                    })
                }

                t
                    .play()
                    .then(() => {
                        resolve();
                    });
            } else {
                resolve();
            }
        });
    };

    // Fct - Done
    const done = () => {
        return new Promise(resolve => {
            resolve();
        });
    };

    // Init & Proceed
    init().then(() => {
        loadedFonts().then(() => {
            Promise.all([titleShow(), txtShow()]).then(() => {
                done();
            });
        });
    });
};

/**
 * Destroy
 */
HeroTxt.prototype.destroy = function () {
    const that = this;

    that.events.destroy();
};

/**
 * Constants
 */
HeroTxt.SELECTOR = '.js-heroTxt';
HeroTxt.AUTOSTART = true;

export default HeroTxt;

import select from '@reef/js/fn/select/select';
import each from '@reef/js/helpers/collection/each';
import parseBool from '@reef/js/helpers/lang/parseBool';
import setUndefined from '@reef/js/helpers/object/setUndefined';
import className from '@reef/js/helpers/utils/className';
import attrOption from '@reef/js/utilities/.internal/options/attrOption';
import EventsManager from '@reef/js/utilities/EventsManager';
import ScrollVibe from '@reef/js/utilities/ScrollVibe';

/*
 * Header functionalities
 *
 * @author Funky Fizz
 * @copyright 2021-present Petra Baggia
 *
 * @param {Node} el
 * @param {Object} options
 */
const Header = function (el, options) {
    const that = this;

    // Options - Selector
    setUndefined(options, {
        selector: Header.SELECTOR,

        // Options
        reverseScroll: Header.REVERSE_SCROLL
    });

    // Selectors
    const selectors = {

        // Statuses
        vHidden: '.v--hidden',

        // Elements
        size: `${options.selector}__size`,
    };

    // Set Options from Attr
    options = attrOption(options, el, ['reverseScroll'], options.selector);

    // Boolean values
    options.reverseScroll = parseBool(options.reverseScroll);

    // Element, Options, Selectors & Events
    that.el = el;
    that.options = options;
    that.selectors = selectors;
    that.events = new EventsManager();
    that.vibes = [];

    // Init
    that.init();
};

/**
 * Init
 */
Header.prototype.init = function () {
    const that = this;

    // Elements
    const elSize = select(that.selectors.size);

    // Classes
    const classHidden = className(that.selectors.vHidden);

    // Create Vibe that will hide the header once the shadow has reached the top of
    // the viewport
    that.vibes.push(new ScrollVibe(elSize, {

        do: {
            target: that.el,
            start: 'bottom top',
            reverseScroll: that.options.reverseScroll,
            class: classHidden
        }
    }));
};

/**
 * Destroy
 */
Header.prototype.destroy = function () {
    const that = this;

    that.events.destroy();
    each(that.vibes, vibe => {
        vibe.destroy();
    });
};

/**
 * Constants
 */
Header.SELECTOR = '.js-header';
Header.REVERSE_SCROLL = true;

export default Header;

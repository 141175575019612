import addClass from '@reef/js/fn/attributes/addClass';
import removeClass from '@reef/js/fn/attributes/removeClass';
import each from '@reef/js/helpers/collection/each';
import select from '@reef/js/fn/select/select';
import selectAll from '@reef/js/fn/select/selectAll';
import setUndefined from '@reef/js/helpers/object/setUndefined';
import className from '@reef/js/helpers/utils/className';
import getAttrOption from '@reef/js//utilities/.internal/options/getAttrOption';
import EventsManager from '@reef/js/utilities/EventsManager';

/*
 * BookingDateArrival functionalities
 *
 * @author Funky Fizz
 * @copyright 2021-present Petra Baggia
 *
 * @param {Node} el
 * @param {Object} options
 */
const BookingDateArrival = function (el, options) {
    const that = this;

    // Options - Selector
    setUndefined(options, {
        selector: BookingDateArrival.SELECTOR
    });

    // Selectors
    const selectors = {

        // Statuses
        vSelected: '.v--selected',

        // Elements
        arrival: `${options.selector}__arrival`,
        inputDateArrival: 'input[name=\'dateArrival\']'
    };

    // Element, Options, Selectors & Events
    that.el = el;
    that.options = options;
    that.selectors = selectors;
    that.events = new EventsManager();

    // Init
    that.init();
};

/**
 * Init
 */
BookingDateArrival.prototype.init = function () {
    const that = this;

    // Elements
    const elArrivals = selectAll(that.selectors.arrival, that.el);
    const elInputDateArrival = select(that.selectors.inputDateArrival, that.el);

    // Classes
    const classSelected = className(that.selectors.vSelected);

    // Listener - Click / Arrivals
    each(elArrivals, elArrival => {
        that.events.on(elArrival, 'click', e => {
            e.preventDefault();
            removeClass(elArrivals, classSelected);
            addClass(elArrival, classSelected);
            elInputDateArrival.value = getAttrOption(elArrival, 'dateArrival', that.options.selector, '');
        });
    });
};

/**
 * Destroy
 */
BookingDateArrival.prototype.destroy = function () {
    const that = this;

    that.events.destroy();
};

/**
 * Constants
 */
BookingDateArrival.SELECTOR = '.js-bookingDateArrival';

export default BookingDateArrival;

/*
 * Main variable for the application.
 *
 * @author Funky Fizz
 * @copyright 2021-present Petra Baggia
 */

const App = {

    // Plugins
    plugins: {}
};

export default App;

import select from '@reef/js/fn/select/select';
import each from '@reef/js/helpers/collection/each';
import setUndefined from '@reef/js/helpers/object/setUndefined';
import EventsManager from '@reef/js/utilities/EventsManager';
import ScrollVibe from '@reef/js/utilities/ScrollVibe';

/*
 * Puzzle functionalities
 *
 * @author Funky Fizz
 * @copyright 2021-present Petra Baggia
 *
 * @param {Node} el
 * @param {Object} options
 */
const Puzzle = function (el, options) {
    const that = this;

    // Options - Selector
    setUndefined(options, {
        selector: Puzzle.SELECTOR
    });

    // Selectors
    const selectors = {

        // Elements
        scene: `${options.selector}__scene`,
        media: `${options.selector}__media`,
        overlayLeft: `${options.selector}__overlay-left`,
        overlayRight: `${options.selector}__overlay-right`,
        overlayTop: `${options.selector}__overlay-top`,
        overlayBottom: `${options.selector}__overlay-bottom`,
        overlayCenter: `${options.selector}__overlay-center`
    };

    // Element, Options, Selectors & Events
    that.el = el;
    that.options = options;
    that.selectors = selectors;
    that.events = new EventsManager();
    that.vibes = [];

    // Init
    that.init();
};

/**
 * Init
 */
Puzzle.prototype.init = function () {
    const that = this;

    // Elements
    const elScene = select(that.selectors.scene, that.el);
    const elMedia = select(that.selectors.media, elScene);
    const elMediaImg = select('img', elMedia);
    const elOverlayLeft = select(that.selectors.overlayLeft, elScene);
    const elOverlayRight = select(that.selectors.overlayRight, elScene);
    const elOverlayTop = select(that.selectors.overlayTop, elScene);
    const elOverlayBottom = select(that.selectors.overlayBottom, elScene);
    const elOverlayCenter = select(that.selectors.overlayCenter, elScene);

    // Fct - Init
    const init = () => {
        return new Promise(resolve => {
            resolve();
        });
    };

    // Fct - Vibe
    const vibe = () => {
        return new Promise(resolve => {

            that.vibes.push(new ScrollVibe(elScene, {

                do: [

                    // Pin
                    {
                        trigger: elScene,
                        start: 'top top',
                        end: 'bottom top',
                        pin: true,
                        pinFocus: true
                    },

                    // Hide Overlays
                    {
                        target: [elOverlayLeft, elOverlayRight, elOverlayCenter],
                        start: 'top top',
                        duration: '90%',
                        parallax: {
                            width: {
                                to: 0,
                                ease: 'power2.in'
                            }
                        }
                    },
                    {
                        target: [elOverlayTop, elOverlayBottom],
                        start: 'top top',
                        duration: '70%',
                        parallax: {
                            height: {
                                to: 0,
                                ease: 'power2.in'
                            }
                        }
                    },

                    // Tween Media
                    {
                        start: 'top top',
                        target: elMedia,
                        reverse: false,
                        tween: {
                            scale: {
                                from: 1.25,
                                to: 1,
                                speed: 12500,
                                ease: 'power0.in'
                            }
                        }
                    },

                    // Smooth Scroll
                    {
                        target: elMediaImg,
                        start: 'top bottom',
                        duration: '95%',
                        imageLoaded: true,
                        parallax: {
                            y: {
                                from: '-20%',
                                to: 0,
                                ease: 'power0.in'
                            }
                        }
                    },
                    {

                        trigger: elScene,
                        target: elMediaImg,
                        start: 'bottom 50%',
                        duration: '50%',
                        imageLoaded: true,
                        parallax: {
                            y: {
                                to: '20%',
                                ease: 'power0.in'
                            }
                        }
                    }
                ]
            }));

            ScrollVibe.refresh();

            resolve();
        });
    };

    // Init & Proceed
    init().then(() => {
        vibe();
    });
};

/**
 * Destroy
 */
Puzzle.prototype.destroy = function () {
    const that = this;

    that.events.destroy();
    each(that.vibes, vibe => {
        vibe.destroy();
    });
};

/**
 * Constants
 */
Puzzle.SELECTOR = '.js-puzzle';

export default Puzzle;

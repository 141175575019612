/*
 * Returns a function, that, as long as it continues to be invoked, will not be
 * triggered. The function will be called after it stops being called for N
 * milliseconds. If `immediate` is passed, trigger the function on the leading edge,
 * instead of the trailing.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {function} fct
 * @param {number} wait
 * @param {boolean} immediate
 */
export default function (fct, wait, immediate) {
    let timeout;
    return function () {
        const context = this;
        const args = arguments;
        const later = function () {
            timeout = null;
            if (!immediate) {
                fct.apply(context, args);
            }
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) {
            fct.apply(context, args);
        }
    };
}
